<template>
    <div class="group">
        <div class="expert-avatar overflow-hidden rounded mt-10 relative">
          <img v-if="expert.thumbnail" class="w-full object-cover transition duration-300 transform group-hover:-translate-y-1 group-hover:scale-105" :src="expert.thumbnail" alt="expert avatar" style="height: 350px">
          <div v-if="expert.position" class="absolute top-0 left-0 m-2 z-10">
              <div class="inline-flex relative text-center w-12 md:w-14 flex items-center justify-center">
                  <img src="@/assets/icons/shield_with_gradient.svg" alt="" class="w-12 h-12 md:w-14 md:h-14">
                  <div class="absolute text-white font-bold text-sm pb-1">
                      {{ expert.position }}
                  </div>
              </div>
          </div>
          <div class="flex items-center absolute bottom-0 right-0 px-2 py-1 m-2 rounded-full bg-gray-900 bg-opacity-90">
            <span class="text-xs font-medium text-white">{{ salePrice }} p/u</span>
          </div>
        </div>
        <div class="expert-info mt-4">
            <div class="flex flex-col justify-between">
                <span class="text-base text-white md:text-lg truncate ...">{{ expert.first_name }} <span class="font-medium">{{ expert.last_name }}</span></span>
                <span class="text-xs font-medium text-gray-500">{{ t('age', {age: expert.age}) }}</span>
            </div>
        </div>
      </div>
    
</template>

<script>
import useI18n from "@/modules/globalI18n";
import { humanReadablePriceCeil } from "@/helpers/CurrencyHelper"

export default {
    name: "ExpertItem",
    setup() {
        return useI18n({
            componentPrefix: "ExpertItem"
        });
    },
    computed: {
        salePrice() {
            return humanReadablePriceCeil(this.expert.sale_hourly_price*100);
        }
    },
    props: {
        expert: Object,
    },
}
</script>

<style scoped>
    .expert-info {
      max-width: 220px;
    }
    .expert-avatar {
        width: 220px;
        height: 340px;
    }
</style>