<template>
    <div>
        <div class="flex flex-col">
            <div class="flex-initial mb-6">
                <TinyNavigation class="fixed z-40 pt-6 px-4 float-left" :prev="{name: 'dashboard'}" />

                <div class="pt-6 md:pt-6 px-4 md:px-10 flex ml-20 flex-row md:flex-row-reverse">
                    <input type="text" v-model="searchquery" :placeholder="t('search')" class="py-2 px-2 md:py-3 md:px-3 border rounded text-white text-sm font-medium border-gray-500 bg-black focus:bg-gray-900 hover:bg-gray-900 transition-all duration-200">
                </div>
            </div>

            <div class="flex-initial pt-6">
                <h3 class="heading-1 px-4 capitalize">{{ title_page }}</h3>
            </div>
            <div class="flex-auto my-4 mx-4 md:mx-4">
                <div class="grid gap-6 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 justify-items-center">
                     <template v-for="expert in filterd_experts" :key="expert.id">
                        <a @click.prevent="redirectToExpertIfAvailable(expert)" >
                            <ExpertItem :expert="expert" />
                        </a>
                     </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ExpertItem from "@/components/dashboard/ExpertItem";
import { indexExperts } from "@/helpers/api/ExpertHelper";
import TinyNavigation from "@/components/TinyNavigation";
import { fuzzyExpertFilter } from "@/helpers/ExpertFilter";
import messages from "@/helpers/storage/MessageHelper";
import { expertRolePlural } from "@/models/ExpertFactory";
import useI18n from "@/modules/globalI18n";

export default {
    name: "ExpertIndex",
    props: ['role'],
    components: { TinyNavigation, ExpertItem },
    setup() {
        return useI18n({
            viewPrefix: "ExpertIndex"
        });
    },
    methods: {
        redirectToExpertIfAvailable(expert) {
            if (!expert.available) {
                messages.warn(this.t('messages.expert_unavailable'))
                return;
            }
            this.$router.push({ name: 'expert.show', params: { id: expert.id } })
        }
    },
    computed: {
        title_page: function (){
            return expertRolePlural(this.role);
        },
        filterd_experts: function() {
            return this.experts.filter(expert => expert.isRole(this.role)).filter(fuzzyExpertFilter(this.searchquery));
        },
    },
    created(){
        // console.log('role', this.role)
        indexExperts()
            .then((coaches) => {
                this.experts = coaches;
            })
            .catch(err => {
                console.error(err);
            });
    },
    data(){
        return {     
            experts: [],
            searchquery: "",
        };
    },
}
</script>